@use "@scss/global.scss" as *;

.layout {
  min-height: 100vh;
  height: max-content;
  width: 100%;
  overflow-x: clip;

  &.navbar-content-footer {
    display: block;
    min-height: calc(100vh - $navbar-height);

    .navbar-part {
      position: relative;
      width: 100%;
      height: $top-bar-height;

      @media screen and (max-width: $lg) {
        height: $top-bar-height-mobile;
      }
    }

    .content-part {
      width: 100%;
      height: max-content;
    }

    .footer-part {
      width: 100%;
      height: max-content;
    }
  }
  &.navbar-content {
    display: block;
    min-height: 100vh;

    .navbar-part {
      position: relative;
      width: 100%;
      height: $navbar-height;

      @media screen and (max-width: $lg) {
        // height: $navba;
      }
    }

    .content-part {
      width: 100%;
      height: max-content;
    }
  }
  &.navbar-sidebar-content {
    display: flex;
    flex-direction: column;

    .navbar-part {
      width: 100%;
      height: 100%;
      max-height: 60px;
    }

    .bottom-part {
      flex: 1;
      display: flex;
    }

    .sidebar-part {
      height: 100%;
      width: 100%;
      max-width: 256px;
    }

    .content-part {
      flex: 1;
    }
  }
  &.sidebar-navbar-content {
    display: flex;
    flex-direction: row;

    .sidebar-part {
      height: 100%;
      width: 100%;
      max-width: 256px;
    }

    .right-part {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .navbar-part {
      width: 100%;
      height: 100%;
      max-height: 60px;
    }

    .content-part {
      flex: 1;
    }
  }
}
