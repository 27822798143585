@use "./src/scss/global.scss" as *;

.footer-show-more {
  path {
    transition: all 0.3s ease-in-out;
  }

  &-icon {
    transition: transform 0.4s ease-in-out;
  }

  &-active {
    .footer-show-more-icon {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    path {
      stroke: $black-0;
    }
  }
}
