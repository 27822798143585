@use "./src/scss/global.scss" as *;

nav.navbar {
  height: $navbar-height;
  width: 100%;
  position: fixed;
  background-color: $white-0;
  padding: 0px calc($grid-gutter-x * 0.5);
  left: 0px;
  z-index: 10;
  transition: top 0.2s ease-in-out;

  &.up {
    top: $top-bar-height;

    @media (max-width: $lg) {
      top: $top-bar-height-mobile;
    }
  }

  &.down {
    top: calc(0px - $navbar-height - 4px);
  }

  .full-height {
    height: 100%;
  }

  .nav-max-width {
    margin: auto;
    width: $page-max-width;
    max-width: 100%;
  }

  .hamburger {
    padding: 15px;
    padding-right: calc($grid-gutter-x * 0.5);
    position: absolute;
    right: 0px;
    top: 0px;
    outline: none;
    height: 100%;

    img {
      width: max-content;
      height: max-content;
    }
  }

  .mobile-navigation {
    height: 100vh;
    max-width: 247px;
    width: 100%;
    background-color: $white-0;
    box-shadow: $bs-4;
    position: absolute;
    top: $navbar-height - 1px;
    z-index: 9;
    padding: 8px 24px;
    transition: right 0.5s ease-in-out;

    &.show {
      right: 0px;
    }

    &.hide {
      right: -100%;
    }

    .nav-link {
      padding: 15px 16px;
      font-size: 14px;
    }
  }
}
