@use "./src/scss/global.scss" as *;

@mixin hover {
  cursor: pointer;
  user-select: none;

  &.primary-btn {
    background-color: $primary-hover;
    box-shadow: $bs-hover;
  }
  &.secondary-btn {
    color: $black-1;
    box-shadow: $bs-4;
  }
  &.trietary-btn {
    background-color: $primary-hover;
    border: 1px solid transparent;
  }
  &.outline-btn {
    background-color: $white-0;
    color: $black-0;
  }

  @include mq($lg) {
    &.primary-btn,
    &.secondary-btn {
      box-shadow: $bs-active-mobile;
    }
  }
}

%hover {
  @include hover;
}

@mixin active {
  background-color: $primary-pressed !important;
  box-shadow: $bs-hover;
}

%active {
  @include active;
}

.g2n-button {
  min-width: $size-dx * 30;
  width: max-content;
  color: $black-0;
  transition: all 0.3s ease-in-out;
  height: 50px;

  &.uppercase {
    text-transform: uppercase;
  }

  &.full-size {
    width: 100%;
  }

  &.primary-btn {
    font-size: $fs-5;
    padding: 0px $size-4;
    line-height: 29.5px;
    font-weight: $medium;
    letter-spacing: $ls-4;

    background-color: $primary;
    box-shadow: unset;

    &:active {
      @extend %active;
    }
  }
  &.secondary-btn {
    font-size: $fs-6;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    color: $black-4;
    background-color: $white-2;
    border: 1px solid $white-3;
    box-shadow: unset;

    &:active {
      @extend %active;
    }
  }
  &.trietary-btn {
    font-size: $fs-7;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    height: 42px;
    border: 1px solid $black-0;
    background-color: transparent;
    &:active {
      @extend %active;
    }
  }

  &.outline-btn {
    font-size: $fs-6;
    padding: 0px $size-4;
    line-height: 29.5px;
    letter-spacing: $ls-4;
    font-weight: $medium;
    border: 1px solid $primary;
    color: $primary;
    background-color: transparent;

    &:active {
      @extend %active;
    }

    // &:hover {
    //   background-color: $white-0;
    //   color: $white-0;
    // }
  }

  &:hover,
  &.hover {
    @extend %hover;
  }

  &.link-btn {
    font-weight: $medium;
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.24;
    pointer-events: none;
  }

  @media (max-width: $lg) {
    background-color: $primary-pressed;
    border: 1px solid transparent;
    width: 100%;
  }
}
