@use "@scss/variables.scss" as *;

.white-0 {
  color: $white-0;
}

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  color: $black-0;
  background-color: $white-1;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
}

html,
body,
section,
#root {
  height: max-content;
  width: 100%;
}

section {
  padding: 0px calc($gutter-x * 0.5);
}

a {
  color: unset;
  text-decoration: none;
}

canvas {
  vertical-align: bottom;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}

hr {
  border: none;
}

img {
  vertical-align: bottom;
}

#overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
  z-index: 1000;

  .alert {
    z-index: 6001;
  }
  .notification {
    z-index: 5001;
  }
  .message {
    z-index: 4001;
  }
  .modal {
    z-index: 3001;
  }
  .dialog {
    z-index: 2001;
  }
  .drawer {
    z-index: 1001;
  }
}
