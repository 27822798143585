@use "./variables.scss" as *;
@use "./mixins.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap");

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-0;
}

h1,
.h1 {
  @include h1;
}
h2,
.h2 {
  @include h2;
}
h3,
.h3 {
  @include h3;
}
h4,
.h4 {
  @include h4;
}

.p1 {
  @include p1;
}
.p2 {
  @include p2;
}
.p3 {
  @include p3;
}
.p4 {
  @include p4;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.col {
  flex-direction: column !important;
}
.row {
  flex-direction: row !important;
}
.grid {
  flex-wrap: wrap !important;
}

.light {
  font-weight: $light;
}
.regular {
  font-weight: $regular;
}
.medium {
  font-weight: $medium;
}
.semiBold {
  font-weight: $semiBold;
}
.bold {
  font-weight: $bold;
}

.lh-1 {
  line-height: $lh-1;
}
.lh-2 {
  line-height: $lh-2;
}
.lh-3 {
  line-height: $lh-3;
}
.lh-4 {
  line-height: $lh-4;
}

.ls-1 {
  letter-spacing: $ls-1;
}
.ls-2 {
  letter-spacing: $ls-2;
}
.ls-3 {
  letter-spacing: $ls-3;
}
.ls-4 {
  letter-spacing: $ls-4;
}
.ls-5 {
  letter-spacing: $ls-5;
}
.ls-6 {
  letter-spacing: $ls-6;
}

.fs-1 {
  font-size: $fs-1;
}
.fs-2 {
  font-size: $fs-2;
}
.fs-3 {
  font-size: $fs-3;
}
.fs-4 {
  font-size: $fs-4;
}
.fs-5 {
  font-size: $fs-5;
}
.fs-6 {
  font-size: $fs-6;
}
.fs-7 {
  font-size: $fs-7;
}
.fs-8 {
  font-size: $fs-8;
}
.fs-9 {
  font-size: $fs-9;
}

.primary {
  color: $primary;
}
.primary-bg {
  background-color: $primary;
}

.danger {
  color: $danger;
}
.danger-bg {
  background-color: $danger;
}

.success {
  color: $success;
}

.white-0 {
  color: $white-0;
}
.white-0-bg {
  background-color: $white-0;
}

.white-1 {
  color: $white-1;
}
.white-1-bg {
  background-color: $white-1;
}
.white-2 {
  color: $white-2;
}
.white-2-bg {
  background-color: $white-2;
}
.white-3 {
  color: $white-3;
}
.white-3-bg {
  background-color: $white-3;
}
.white-4 {
  color: $white-4;
}
.white-4-bg {
  background-color: $white-4;
}

.black-0 {
  color: $black-0;
}
.black-0-bg {
  background-color: $black-0;
}
.black-1 {
  color: $black-1;
}
.black-1-bg {
  background-color: $black-1;
}
.black-2 {
  color: $black-2;
}
.black-2-bg {
  background-color: $black-2;
}
.black-3 {
  color: $black-3;
}
.black-3-bg {
  background-color: $black-3;
}
.black-4 {
  color: $black-4;
}
.black-4-bg {
  background-color: $black-4;
}
.black-5 {
  color: $black-5;
}
.black-5-bg {
  background-color: $black-5;
}
.black-6 {
  color: $black-6;
}
.black-6-bg {
  background-color: $black-6;
}
