@use "./src/scss/global.scss" as *;

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(25px);
  }
}

.suspense-loading {
  width: 100vw;
  height: 100vh;
  background-color: $white-1;
  position: absolute;
  left: 0px;
  top: 0px;
  @include flex-center;

  .logo-animation {
    animation: MoveUpDown 2.5s ease-in-out infinite;
    aspect-ratio: 1/1;
  }
}
