@use "./src/scss/global.scss" as *;

.newsletter {
  form {
    position: relative;

    .response {
      position: absolute;
      bottom: -24px;
      left: 0px;
    }
  }
}
