@use "@scss/global.scss" as *;

.task {
  width: 100%;
  .message {
    word-break: break-word;
    padding: 2px;
    width: max-content;
    max-width: 100%;
    line-height: 1.4;
    letter-spacing: 0.3px;
  }

  .button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: max-content;
    min-width: 80px;
    background-color: darken(#ffffff, 30%);
    height: 24px;

    &.show {
      opacity: 1;
    }
  }

  .max-width {
    max-width: calc(100% - 88px);
  }
}
