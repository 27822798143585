@use "./src/scss/global.scss" as *;

.reload-page-logo {
  height: max-content;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;

  &.hide {
    opacity: 0;
  }
}
