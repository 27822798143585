@use "./src/scss/global.scss" as *;

.collapse {
  width: 100%;

  &-content {
    overflow-y: clip;
    width: 100%;
    transition-property: height, margin;
    transition: 0.5s cubic-bezier(0.69, -0.23, 0.31, 1.23);
    will-change: height, margin;
  }
}
