@use "@scss/global.scss" as *;

.footer {
  padding: 0px calc($gutter-x * 0.5);
  padding-top: 80px;
  margin: auto;
  height: max-content;
  background-color: $black-0;

  .our-history {
    @include h3;
  }

  .social-link {
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }

  .nav-max-width {
    height: 48px;
    margin: auto;
    max-width: $page-max-width;
  }

  .footer-header {
    font-weight: $medium;
    margin-bottom: 16px;
    color: $white-4;
  }

  .social-media {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 320px;
  }

  .contact-footer {
    margin-left: 60px;
  }

  .contact-title {
    color: $white-4;
  }
  .footer-img {
    width: 66px;
    height: 40.45px;
  }
  .footer-title {
    color: $white-4;
  }
  .go2nft-app-links {
    height: max-content;
    a {
      height: max-content;
    }
  }
}

@media (max-width: $lg) {
  .footer {
    padding-bottom: 80px;
  }
}
