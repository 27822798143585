@use "./src/scss/global.scss" as *;

.social-link {
  width: 40px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: auto;
    width: 18px;

    &:hover {
      path:first-child {
        fill: black;
      }
    }
  }
}
