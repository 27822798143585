@use "@scss/global.scss" as *;

.stack {
  display: flex !important;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  z-index: 0;

  &.full-size {
    width: 100%;
    height: 100%;
  }

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 100%;
  }
}
