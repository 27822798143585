@use "./src/scss/global.scss" as *;

$animation-timing: 0.1s;

.nav-link {
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
  transition: color $animation-timing ease-in-out;
  position: relative;
  color: $black-2;
  font-size: $fs-7;

  &:hover:not(.active) {
    color: $primary-hover;
  }

  &.active {
    color: $black-0;
    pointer-events: none;
  }

  @include mq-min($lg) {
    width: max-content;
    justify-content: unset;

    &.active::after {
      animation: appear ease-in-out $animation-timing;
      $line-height: 4px;
      @include after($line-height, 100%);
      left: 0px;
      bottom: -$line-height;
      background-color: $primary;
    }
  }
  @include mq($lg) {
    width: 100%;
    justify-content: center;
  }
}
