@use "@scss/global.scss" as *;

$local-border-radius: 8px;

.current-tasks {
  position: fixed;
  z-index: 10;
  right: 0px;
  top: 72px;
  height: max-content;
  width: max-content;
  width: 350px;
  border-radius: $local-border-radius 0px 0px $local-border-radius;
  border: 2px solid $primary;

  .header {
    background-color: $primary;
    filter: saturate(0);
    padding: 10px;
    border-radius: $local-border-radius 0px 0px 0px;
  }

  .body {
    background-color: $primary-pressed;
    filter: saturate(0);
    padding: 10px;
    height: 100%;
    border-radius: 0px 0px 0px $local-border-radius;

    .direct-tasks-wrapper {
      max-width: 100%;
    }
  }

  .custom-input {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    border: none;
  }
}
