@use "@scss/global.scss" as *;

.total-input {
  position: relative;
  cursor: text;

  &,
  * {
    transition: all 0.3s ease-in-out;
  }

  &.required {
    .label:after {
      content: "*";
      color: $danger;
    }
  }

  .label {
    width: 100%;
  }

  .input-wrapper {
    width: 100%;
    height: max-content;
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: $white-0;

    .input-itself {
      padding: 14px 24px;
      background-color: transparent;
      width: 100%;
      line-height: 160%;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }

      &::placeholder {
        color: $black-3;
        font-weight: $regular;
        letter-spacing: $ls-4;
        font-size: $fs-6;
      }
    }

    &.hover:not(.correct, .wrong) {
      box-shadow: $bs-5;
    }
    &.focus:not(.correct, .wrong) {
      border: 1px solid $primary;
      box-shadow: $bs-5;
    }
  }

  .error {
    color: $danger;
  }

  // &.correct {
  //   .label {
  //     color: $success;
  //   }
  //   .input-wrapper {
  //     border-color: $success;
  //     box-shadow: none;
  //   }
  // }
  &.wrong {
    .label {
      color: $danger;
    }
    .input-wrapper {
      border-color: $danger;
      box-shadow: none;
    }
  }

  &.secondary {
    &,
    * {
      background-color: transparent;
      color: $white-4;
    }

    .input-wrapper {
      border: 1px solid $white-4;
      padding-right: 8px;

      .input-itself {
        &::placeholder {
          color: $black-2;
        }
      }
    }
  }
}
