// COLORS
$primary: #ffa800;
$primary-hover: #ffb933;
$primary-pressed: #ffcb66;

$white-0: #ffffff;
$white-1: #f6f6f6;
$white-2: #ececeb;
$white-3: #d9d9d8;
$white-4: #bcbcba;

$black-0: #1b1a17;
$black-1: #494845;
$black-2: #767674;
$black-3: #a8a8a7;
$black-4: #838382;
$black-5: #919190;
$black-6: #605f5d;

$success: #3cbc81;
$danger: #e74a3b;
// COLORS

// BREAKPOINTS
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);
//BREAKPOINTS

// RESPONSIVE
$responsive: (
  l: "start",
  c: "center",
  r: "end"
);
// RESPONSIVE

//POSITION VARIANTS
$position-variants: (
  l: "flex-start",
  c: "center",
  r: "flex-end"
);
//POSITION VARIANTS

// dX
$size-dx: 8px;
// dX

$gutter-x: 30px;
$grid-gutter-x: 30px;

$navbar-height: 48px;

$top-bar-height: 0px;
$top-bar-height-mobile: 0px;

$page-max-width: 1920px;
$content-max-width: 1140px;

// SIZES
$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;

$size-variants: (
  0: 0,
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10
);
// SIZES

$order-variants: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;

$percentage-variants: (
  100: 100%,
  90: 90%,
  80: 80%,
  70: 70%,
  60: 60%,
  50: 50%,
  40: 40%,
  30: 30%,
  20: 20%,
  10: 10%,
  0: 0%
);

$display-variants: (
  b: block,
  f: flex,
  n: none
);

// FONT WEIGHTS
$light: 200;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
// FONT WEIGHTS

// LINE HEIGHTS
$lh-1: 130%;
$lh-2: 140%;
$lh-3: 160%;
$lh-4: 170%;
$lh-5: 150%;
// LINE HEIGHTS

// LETTER SPACING
$ls-1: 0px;
$ls-2: 0.2px;
$ls-3: 0.5px;
$ls-4: 0.6px;
$ls-5: 2%;
$ls-6: 3%;
// LETTER SPACING

//FONT-SIZES
$fs-0: 96px;
$fs-1: 48px;
$fs-2: 32px;
$fs-3: 18px;
$fs-4: 16px;
$fs-5: 16px;
$fs-6: 14px;
$fs-7: 12px;
$fs-8: 11px;

$fs-9: 24px;
//FONT-SIZES

//BOX-SHADOWS
$bs-default: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
$bs-hover: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
$bs-active: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

$bs-default-mobile: $bs-active;
$bs-active-mobile: $bs-hover;

$bs-2: 4px 4px 0px 0px $primary;
$bs-3: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
  0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01),
  0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
$bs-4: 0px 11.1704px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 5.8px 13px rgba(0, 0, 0, 0.035),
  0px 2.36296px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 0.537037px 3.14815px rgba(0, 0, 0, 0.0168519);
$bs-5: 0px 0px 14px rgba(255, 168, 0, 0.07), 0px 0px 8.19907px rgba(255, 168, 0, 0.0531481),
  0px 0px 4.45926px rgba(255, 168, 0, 0.0425185), 0px 0px 2.275px rgba(255, 168, 0, 0.035),
  0px 0px 1.14074px rgba(255, 168, 0, 0.0274815), 0px 0px 0.550926px rgba(255, 168, 0, 0.0168519);
$bs-6: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);

$bs--1: inset 0px 0.5px 4px rgba(96, 97, 112, 0.32);
$bs--0: 0px 0px 0px rgba(0, 0, 0, 0);
$bs--1: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
$bs--2: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
$bs--3: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
$bs--4: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
$bs--5: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
$bs--6: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
$bs--7: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.08);

//GRID
$grid-values: (
  1: calc((1 / 12) * 100%),
  2: calc((2 / 12) * 100%),
  3: calc((3 / 12) * 100%),
  4: calc((4 / 12) * 100%),
  5: calc((5 / 12) * 100%),
  6: calc((6 / 12) * 100%),
  7: calc((7 / 12) * 100%),
  8: calc((8 / 12) * 100%),
  9: calc((9 / 12) * 100%),
  10: calc((10 / 12) * 100%),
  11: calc((10 / 12) * 100%),
  12: calc((12 / 12) * 100%)
);
//GRID
